<template>
<div class="flex flex-col mb-10">

    <div style="margin-left: -10px; padding:30px; border: 1px solid #8080807a; border-radius: 25px;; border-radius: 5px;">

        <Content textColor='black' class="my-3 text-uppercase" text="AdOns" />
        <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-50 md-xsmall-size-50 md-small-size-50">
                <Content textColor='black' class="m-0 pt-2" text="PTE Practice" />
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-50 md-small-size-50">
                <md-switch style="margin-top: 20px;" v-model="form.is_pte_practice"></md-switch>
            </div>
        </div>
        <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-50 md-xsmall-size-50 md-small-size-50">
                <Content textColor='black' text="Practice Test" />
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-50 md-small-size-50">
                <md-switch v-model="form.is_practice_test"></md-switch>
            </div>
        </div>
        <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-50 md-xsmall-size-50 md-small-size-50">
                <Content textColor='black' text="Mock Test" />
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-50 md-small-size-50">
                <md-switch v-model="form.is_mock_test"></md-switch>
            </div>
        </div>
        <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-50 md-xsmall-size-50 md-small-size-50">
                <Content textColor='black' text="Quiz" />
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-50 md-small-size-50">
                <md-switch v-model="form.is_quiz"></md-switch>
            </div>
        </div>
        <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-50 md-xsmall-size-50 md-small-size-50">
                <Content textColor='black' text="Materials" />
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-50 md-small-size-50">
                <md-switch v-model="form.is_materials"></md-switch>
            </div>
        </div>
        <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-50 md-xsmall-size-50 md-small-size-50">
                <Content textColor='black' text="Live Class" />
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-50 md-small-size-50">
                <md-switch v-model="form.is_live_class"></md-switch>
            </div>
        </div>
        <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-50 md-xsmall-size-50 md-small-size-50">
                <Content textColor='black' text="Webinars" />
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-50 md-small-size-50">
                <md-switch v-model="form.is_webinars"></md-switch>
            </div>
        </div>
        <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-50 md-xsmall-size-50 md-small-size-50">
                <Content textColor='black' text="One-One Appointment" />
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-50 md-small-size-50">
                <md-switch v-model="form.is_one_to_one_appointment"></md-switch>
            </div>
        </div>

    </div>

    <div class="flex justify-end mt-4">
        <md-button class="ml-auto text-uppercase" @click="clearAll">Clear all</md-button>
        <Button :loading="loading" class="bg-victoria rounded text-white mr-0" label="Next" type="button" @click="submit">
        </Button>
    </div>
</div>
</template>

<script>
import {
    Button,
} from "@/components";

import Content from "@/components/atom/Content";

export default {
    components: {
        Button,
        Content
    },

    data() {
        return {
            active: false,
            loading: false,
            radio: false,
            form: {
                is_pte_practice: true,
                is_practice_test: true,
                is_mock_test: true,
                is_quiz: true,
                is_materials: true,
                is_live_class: true,
                is_webinars: true,
                is_one_to_one_appointment: true,
            }

        }
    },

    methods: {
        async reload() {
            this.render = false;
            await this.$nextTick();
            this.render = true;
        },
        formClear() {
            this.form = {

            }
        },

        submit() {

            let formData = this.getLocal('course-create-info');
            formData.package_detail = {
                ...this.form
            };
            this.setLocal('course-create-info', formData);
            this.$emit("menu", this.$route.name);

            this.getPropertiesRoute();

            // window.location.href = 'properties';
        },

        getPropertiesRoute() {

            if (this.form.is_pte_practice) {
                this.$router.push({
                    name: 'courseCreation.properties.ptePractice'
                });
                return;
            }
            if (this.form.is_practice_test) {
                this.$router.push({
                    name: 'courseCreation.properties.practiceTest'
                });
                return;
            }
            if (this.form.is_mock_test) {
                this.$router.push({
                    name: 'courseCreation.properties.mockTest'
                });
                return;
            }
            if (this.form.is_quiz) {
                this.$router.push({
                    name: 'courseCreation.properties.quiz'
                });
                return;
            }
            if (this.form.is_materials) {
                this.$router.push({
                    name: 'courseCreation.properties.materials'
                });
                return;
            }
            if (this.form.is_live_class) {
                this.$router.push({
                    name: 'courseCreation.properties.liveClass'
                });
                return;
            }
            if (this.form.is_webinars) {
                this.$router.push({
                    name: 'courseCreation.properties.webinars'
                });
                return;
            }
            if (this.form.is_one_to_one_appointment) {
                this.$router.push({
                    name: 'courseCreation.properties.OneToOneAppointment'
                });
                return;
            }
        },
        clearAll() {
            this.form = {
                is_pte_practice: true,
                is_practice_test: true,
                is_mock_test: true,
                is_quiz: true,
                is_materials: true,
                is_live_class: true,
                is_webinars: true,
                is_one_to_one_appointment: true,
            }

            let formData = this.getLocal('course-create-info');
            formData.package_detail = {
                ...this.form
            };
            this.setLocal('course-create-info', formData);
            this.$emit("menu", this.$route.name+'#false');
        }
    },

    created() {
        let existData = this.getLocal('course-create-info');
        if (existData && existData.package_detail) this.form = existData.package_detail;

    }
}
</script>
